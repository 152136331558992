<template>
  <div>
    <Toolbar
      :handle-reset="resetForm"
      :handle-submit="onSendForm"
    />
    <TenantAccountSettingForm
      :errors="violations"
      :values="item"
      refForm="createForm"
      ref="createForm"
    />
    <Loading :visible="isLoading" />
  </div>
</template>

<script>
  import { mapActions } from "vuex";
  import { createHelpers } from "vuex-map-fields";
  import TenantAccountSettingForm from "../../components/tenantAccountSetting/Form";
  import Loading from "../../components/Loading";
  import Toolbar from "../../components/Toolbar";
  import CreateMixin from "../../mixins/CreateMixin";

  const servicePrefix = "TenantAccountSetting";

  const { mapFields } = createHelpers({
    getterType: "tenantAccountSetting/getField",
    mutationType: "tenantAccountSetting/updateField",
  });

  export default {
    name: "TenantAccountSettingCreate",
    servicePrefix,
    mixins: [CreateMixin],
    components: {
      Loading,
      Toolbar,
      TenantAccountSettingForm,
    },
    data() {
      return {
        item: {
          invoiceNumberRange: {},
          creditNumberRange: {},
          offerNumberRange: {},
          orderConfirmationNumberRange: {},
          customerNumberRange: {},
          paymentMethods: [],
          dunningLevels: [],
          customerLoginEnabled: true,
        },
        createMessage: this.$t("tenantAccountSettingCreateSuccess"),
      };
    },
    computed: {
      ...mapFields(['error', 'isLoading', 'created', 'violations'])
    },
    methods: {
      ...mapActions("tenantAccountSetting", {
        create: "create",
      }),
    },
  };
</script>
